@use '../includes' as *;

.search_menu {
  @include menu-background;

  position: absolute;
  inset: calc(var(--navigation-height)) 0 0;
  width: 100%;
  height: min-content;
  // Okay, so this is weird. Safari won't let -webkit-backdrop-filter work when the max height is 0.
  // (-webkit-backdrop-filter is used in the menu-background mixin)
  // So, we set it to 1px so the div is "visible" for Safari's rendering engine.
  // However, we then need to set the opacity to 0 so there isn't a weird line hanging out,
  // and then toggle the opacity when the menu is open.
  max-height: 1px;
  overflow: hidden;
  transition:
    max-height var(--duration-half) ease-out,
    opacity var(--duration-half) ease-in;
  opacity: 0;

  &.is_open {
    max-height: 2000px;
    transition: opacity 0 ease-out;
    opacity: 1;
  }

  @include breakpoint($sm) {
    inset-inline-start: auto;
    width: 460px;
  }

  @include breakpoint($mdlg) {
    inset-inline-end: $g32;
    border-radius: 6px;
  }

  svg {
    max-width: 20px;
  }
}

// this bit of CSS replaces a lot of squirelly JavaScript
// it happens only above $md on mouse devices
@include bp-utility-nav-mouse-only {
  .search_menu {
    width: clamp(460px, 44vw, 675px);
    max-height: 500px;
    pointer-events: none;
  }

  // if the menu doesn't have a link with the is_active class
  // (which happens when you are on the search page itself)
  .search_menu_wrapper:not(:has(a[class*='is_active'])) {
    // if the search input or button in the dropdown is in focus
    &:focus-within,
    // or the input is not empty - that is, the user has entered a string
    // we know that when the placeholder isn't shown
    &:has(input:not(:placeholder-shown)),
    // or the search link is hovered
    &:has(.search_link:hover),
    // or the search menu is hovered
    &:has(.search_menu:hover) {
      // then we want to make see the triangle
      .search_link {
        &::before {
          opacity: 1;
        }
      }

      // and show the search menu
      .search_menu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}

.search_menu_inner {
  padding: $g24 $g16;

  @include breakpoint($sm) {
    padding: $g40 $g32;
  }
}

.search_form {
  position: relative;
}

.search_input {
  width: 100%;
  padding: $g16 $g60 $g16 $g16;
  border: 1px solid $white;
  // this border radius is in figma - kind weird, but okay
  border-radius: 7.2px;
  background-color: transparent;
  color: $fog;
  font-size: $f16;

  &::placeholder {
    color: $fog;
    font-size: $f16;
  }
}

.search_submit_button {
  position: absolute;
  inset: $g8 $g8 0 auto;
  width: 40px;
  height: calc(100% - #{$g16});
  padding: 0;
  border: none;
  border-radius: 3px;
  background-color: transparent;
  cursor: pointer;

  svg {
    max-width: 20px;
    fill: $white;
  }
}
