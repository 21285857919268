@use '../includes' as *;

// shared styles between the button and the link
.search_button,
.search_link {
  --color: #{$white};
  --circle-duration: var(--duration-third);
  --line-duration: var(--duration-half);

  align-items: center;
  height: var(--navigation-height);
  padding-inline: calc(var(--navigation-spacing-inline) / 2);

  @include breakpoint($xxxs) {
    padding-inline: var(--navigation-spacing-inline);
  }

  border: 0;
  background-color: transparent;
  cursor: pointer;
}

// there is a search button at mobile / touch screens
.search_button {
  --circle-duration: var(--duration-third);
  --line-duration: var(--duration-half);

  display: flex;

  @include breakpoint($sm) {
    &.is_open {
      background-color: $blue-gray;
    }
  }

  @include bp-utility-nav-mouse-only {
    display: none;
  }
}

// however at $md and above, on devices with a mouse, we use a link
.search_link {
  display: none;

  @include bp-utility-nav-mouse-only {
    @include menu-triangle;

    display: flex;
    position: relative;

    div[class*='line'] {
      transition-delay: 0s;
    }

    &:hover,
    &:focus {
      --color: #{$pebble};
    }
  }
}

.search_icon {
  --stroke-width: 2px;
  --icon-size: 20px;

  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);

  [class*='line'] {
    /* stylelint-disable */
    position: absolute;
    height: var(--stroke-width);
    transform-origin: center left;
    transition-property: width, background-color;
    // the animation works because of the delays, so their
    // order is important
    transition-delay: var(--circle-duration);
    transition-duration: var(--line-duration);
    transition-timing-function: ease-out;
    border-radius: 4px;
    background-color: var(--color);

    .is_closed & {
      transition-delay: 0s;
    }
  }
}

.circle {
  position: absolute;
  inset: 0;
  width: calc(var(--icon-size) * 0.8);
  height: calc(var(--icon-size) * 0.8);
  transition-property: opacity, border-color;
  transition-duration: var(--circle-duration);
  transition-timing-function: ease-out;
  border: var(--stroke-width) solid var(--color);
  border-radius: 50%;

  .is_open & {
    opacity: 0;
  }

  .is_closed & {
    transition-delay: var(--line-duration);
  }
}

.line_1 {
  inset: var(--icon-size) 0 0 var(--icon-size);
  width: calc(var(--icon-size) * 0.571);
  rotate: 225deg;

  .is_open & {
    width: calc(var(--icon-size) * 1.428);
  }
}

.line_2 {
  rotate: -45deg;
}

.line_3 {
  rotate: 135deg;
}

.line_2,
.line_3 {
  inset: calc(var(--icon-size) * 0.5) 0 0 calc(var(--icon-size) * 0.5);
  width: 0;

  .is_open & {
    width: calc(var(--icon-size) * 0.714);
  }
}
